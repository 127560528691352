
export default {
  name: "ButtonPlay",
  props: [
    "podcast",
    "column",
    "light",
    "withoutDuration",
    "inArticle",
    "playList",
    "size"
  ],
  methods: {
    msToMin(number) {
      return this.$converterService.msToMin(number);
    },
    async launchEpisode(event) {
      let listenerCounter = this.$cookies.get("cookieListenerCounter");

      if (!listenerCounter) {
        this.$cookieService.initListenerCounter()
        listenerCounter = this.$cookies.get("cookieListenerCounter");
      }
      event.stopPropagation();
      event.preventDefault();
      const { rolls } = this.podcast;

      const audioPlayerState = this.$store.state.audioPlayer;
      const audioPlayerParams = {
        soundData: this.podcast,
        autoPlay: (this.$auth.isAuthenticated || (listenerCounter?.ListenerNb < this.$config.listenerMax) || audioPlayerState.isLiveStream) ? true : false,
        playList: this.playList || null,
      }
      if (
        !this.$auth.isAuthenticated &&
        listenerCounter.ListenerNb <= this.$config.listenerMax
      ) {

        await this.$cookieService.setDonationPathway(listenerCounter.ListenerNb);
      }
      if(rolls && rolls.podcast_not_roll === false) {
        this.$store.commit("roll/setPreRollShow", rolls.podcast_pre_roll || null);
        this.$store.commit("roll/setPostRollShow", rolls.podcast_post_roll || null);
      }

      if (!audioPlayerState.isPlaying) {
        // Si on relance le son en cours
        if (audioPlayerState.soundData && audioPlayerState.soundData.id == this.podcast.id) {
          if(listenerCounter?.ListenerNb < this.$config.listenerMax) {
            this.$store.dispatch("audioPlayer/play",{same: true});
          } else {
            this.$store.dispatch("audioPlayer/play",{same: false});
          }
        } else {
          // Si on lance un nouveau son
          this.$store.dispatch("audioPlayer/handleAudioPlayer", audioPlayerParams);
          this.$store.commit("audioPlayer/setCurrentTime", 0);
        }
      } else {
        // Mise en pause 
        if (audioPlayerState.soundData && audioPlayerState.soundData.id == this.podcast.id) {
          this.$store.dispatch("audioPlayer/pause");
        } else {
          // Si l'écoute en cours est du live, on nettoie le store et on arrête l'intervalle correspondant
          if(audioPlayerState.isLiveStream) {
            this.$store.dispatch("audioPlayer/unloadMainSoundInstance")
            this.$store.dispatch("audioPlayer/stopLiveUpdateTimeout")
          }
          //  Lancement d'un nouveau son
          this.$store.dispatch("audioPlayer/handleAudioPlayer", audioPlayerParams);
        }
      }
    },
  }
};
